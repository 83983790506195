import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Checkbox, Container, FormControlLabel, Typography } from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';
import ConfirmationScreen from '../../components/ConfirmationScreen';
import HeaderWithTitle from '../../components/HeaderWithTitle';
import WebcamCapture from '../../components/Webcam';
import { callPostApi, uploadDocGetAttributes } from '../../configs/api';
import { routes } from '../../configs/routes';
import { MAX_WIDTH } from '../../helpers';
import { verificationProps } from '../../wrapper';
import PassportInstructions from './PassportInstructions';

enum PASSPORT_SCREENS {
  InstructionsFront,
  CaptureFront,
  InstructionsBack,
  CaptureBack,
  ShowConfirmation,
}

type passportImages = {
  front: string;
  back: string;
}

export default function Passport({ onError, onSuccess, onBack, token, disclaimer, confirmationRequired, passportSkipDisclaimer, passportBackSkipAllowed }: verificationProps) {
  const [screen, setScreen] = useState(PASSPORT_SCREENS.InstructionsFront);
  const [loading, setLoading] = useState(false);
  const [capturedImages, setCapturedImages] = useState<passportImages>({
    front: '',
    back: '',
  });
  const [checkedDisclaimer, setCheckedDisclaimer] = useState(false);
  const [checkedSkipDisclaimer, setCheckedSkipDisclaimer] = useState(false);
  const [confirmationData, setConfirmationData] = useState({});
  const webCamRef = useRef<any>(null);
  const flipButtonRef = useRef<any>(null);
  const captureImage = useCallback(() => {
    webCamRef.current?.click(); // base64

  }, [webCamRef]);

  const setImage = (isFront: boolean, image: string) => setCapturedImages((prev) => ({
    ...prev,
    [isFront ? 'front' : 'back']: image,
  }));

  const handleBackClick = () => {
    switch (screen) {
      case PASSPORT_SCREENS.InstructionsFront:
        onBack();
        break;

      case PASSPORT_SCREENS.CaptureFront:
        setImage(true, '');
        setScreen(PASSPORT_SCREENS.InstructionsFront);
        break;

      case PASSPORT_SCREENS.InstructionsBack:
        setScreen(PASSPORT_SCREENS.CaptureFront);
        break;

      case PASSPORT_SCREENS.CaptureBack:
        setImage(false, '')
        setScreen(PASSPORT_SCREENS.InstructionsBack)
        break;

      case PASSPORT_SCREENS.ShowConfirmation:
        setImage(true, '');
        setImage(false, '');
        onBack();
        break;

    }
  }

  const handleContinueClick = () => {
    switch (screen) {
      case PASSPORT_SCREENS.InstructionsFront:
        setScreen(PASSPORT_SCREENS.CaptureFront);
        break;

      case PASSPORT_SCREENS.CaptureFront:
        if (capturedImages.front) {
          setScreen(PASSPORT_SCREENS.InstructionsBack);
        } else {
          captureImage();
        }
        break;

      case PASSPORT_SCREENS.InstructionsBack:
        setScreen(PASSPORT_SCREENS.CaptureBack);
        break;

      case PASSPORT_SCREENS.CaptureBack:
        if (capturedImages.back) {
          handlePassportImagesSubmit();
        } else {
          captureImage();
        }
        break;

      case PASSPORT_SCREENS.ShowConfirmation:
        submitVerification();
        break;

      default:
        return;
    }
  }

  const skipBackPage = () => {
    handlePassportImagesSubmit(true);
  }

  const handlePassportImagesSubmit = async (skipped?: boolean) => {
    if (!capturedImages.front) {
      if (!skipped) {
        if (!capturedImages.back) {
          return;
        }
      }
    }

    try {
      setLoading(true);
      const images = [];
      if (capturedImages.front && capturedImages.front.length > 0) {
        images.push(capturedImages.front);
      }
      if (capturedImages.back && capturedImages.back.length > 0) {
        images.push(capturedImages.back);
      }
      const res = await uploadDocGetAttributes(routes.passport, "?backendConfirmationRequired=true", images, token);
      if (res?.data?.code === 0) {
        if (!confirmationRequired) {
          submitVerification()
          return
        }
        setConfirmationData(res?.data?.data);
        setScreen(PASSPORT_SCREENS.ShowConfirmation);
      }
    } catch (error) {

    } finally {
      setLoading(false)
    }
  }

  const submitVerification = async () => {
    try {
      setLoading(true);
      const res = await callPostApi(routes.submitPassportConfirmation, "", { "verificationName": "passport" }, {}, token);

      if (res?.data?.code === 0) {
        onSuccess('passport', true, null)
      } else {
        onError('passport', false)
      }
    } catch (error) {

    } finally {
      setLoading(false);
    }
  }

  return (
    <Container maxWidth="sm" sx={{ padding: '12px', height: '85vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <HeaderWithTitle
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexWrap: 'nowrap',
          height: '100%',
          margin: 'auto',
          overflowY: 'auto',
        }}
        title={'Verify Passport'}
        onBack={handleBackClick}
        isBackButtonDisabled={loading}
      >

        {screen === PASSPORT_SCREENS.InstructionsFront &&
          <PassportInstructions isFront={true} />
        }

        {screen === PASSPORT_SCREENS.CaptureFront &&
          <>
            <Box>
              <Typography color="initial" sx={{ fontSize: '14px', fontWeight: '600', lineHeight: '22px', color: '#404052', margin: 0 }}>
                Please scan the front page of your passport
              </Typography>
            </Box>
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              margin: 'auto',
              width: '100%',
              maxWidth: `${MAX_WIDTH}px`,
              backgroundColor: '#444'
            }}>



              {capturedImages.front ? <img
                src={capturedImages.front}
                alt="captured"
                style={{
                  width: '100%',
                  margin: '1em auto'
                }} /> :

                <WebcamCapture
                  setImage={(e: any) => {
                    setImage(true, e);
                  }}
                  buttonRef={webCamRef}
                  flipButtonRef={flipButtonRef}
                  aspectRatio={1.3}
                />}


            </Box>
          </>
        }

        {screen === PASSPORT_SCREENS.InstructionsBack &&
          <PassportInstructions isFront={false} />
        }

        {screen === PASSPORT_SCREENS.CaptureBack &&
          <>
            <Box>
              <Typography color="initial" sx={{ fontSize: '14px', fontWeight: '600', lineHeight: '22px', color: '#404052', margin: 0 }}>
                Please scan the back page of your passport
              </Typography>
            </Box>
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              margin: 'auto',
              width: '100%', maxWidth: `${MAX_WIDTH}px`,
              backgroundColor: '#444'
            }}>

              {capturedImages.back ? <img
                src={capturedImages.back}
                alt="captured"
                style={{
                  width: '100%',
                  margin: '1em auto'
                }} /> :

                <WebcamCapture
                  setImage={(e: any) => {
                    setImage(false, e);
                  }}
                  buttonRef={webCamRef}
                  flipButtonRef={flipButtonRef}
                  aspectRatio={1.3}
                />}

            </Box>
          </>
        }

        {screen === PASSPORT_SCREENS.ShowConfirmation &&
          <ConfirmationScreen data={confirmationData} />
        }

      </HeaderWithTitle>

      <Box>
        {screen !== PASSPORT_SCREENS.InstructionsBack && <FormControlLabel
          required
          control={<Checkbox
            checked={checkedDisclaimer}
            disabled={loading}
            onChange={(e) => setCheckedDisclaimer(e.target.checked)}
          />}
          label={<span style={{ fontSize: '12px', lineHeight: '16px' }}          >
            {disclaimer}
          </span>}
        />}

        {screen == PASSPORT_SCREENS.InstructionsBack && passportBackSkipAllowed && <FormControlLabel
          required
          control={<Checkbox
            checked={checkedSkipDisclaimer}
            disabled={loading}
            onChange={(e) => setCheckedSkipDisclaimer(e.target.checked)}
          />}
          label={<span style={{ fontSize: '12px', lineHeight: '16px' }}          >
            {passportSkipDisclaimer}
          </span>}
        />}

        <Box sx={{ display: 'flex', justifyContent: 'space-evenly', gap: '20px' }}>
          {screen === PASSPORT_SCREENS.InstructionsFront &&

            <Button
              disabled={!checkedDisclaimer}
              onClick={handleContinueClick}
              variant="contained"
              color="primary"
              sx={{ width: '100%', marginTop: '20px' }}
            >
              Start Scanning <ArrowForwardIosIcon />
            </Button>
          }

          {screen === PASSPORT_SCREENS.CaptureFront &&
            <>
              <Button
                onClick={capturedImages.front ? (() => setImage(true, '')) : handleBackClick}
                variant="contained"
                color="primary"
                sx={{ width: '100%', marginTop: '20px' }}
              >
                {capturedImages.front ? 'Retake' : 'Back'}
              </Button>
              <Button
                disabled={!checkedDisclaimer}
                onClick={handleContinueClick}
                variant="contained"
                color="primary"
                sx={{ width: '100%', marginTop: '20px' }}
              >
                {capturedImages.front ? 'Proceed' : 'Capture Front'}
              </Button>
            </>
          }

          {screen === PASSPORT_SCREENS.InstructionsBack &&
            <>
              <Button
                onClick={handleBackClick}
                variant="contained"
                color="primary"
                sx={{ width: '100%', marginTop: '20px' }}
              >
                Back
              </Button>
              <>
                {passportBackSkipAllowed && <LoadingButton
                  onClick={() => skipBackPage()}
                  variant="contained"
                  disabled={!checkedSkipDisclaimer}
                  color="primary"
                  loading={loading}
                  sx={{ width: '100%', marginTop: '20px', backgroundColor: "white" }}
                >
                  Skip
                </LoadingButton>}
                <Button
                  disabled={!checkedDisclaimer || checkedSkipDisclaimer}
                  onClick={handleContinueClick}
                  variant="contained"
                  color="primary"
                  sx={{ width: '100%', marginTop: '20px' }}
                >
                  Continue
                </Button>
              </>
            </>
          }

          {screen === PASSPORT_SCREENS.CaptureBack &&
            <>
              <Button
                disabled={loading}
                onClick={capturedImages.back ? (() => setImage(false, '')) : handleBackClick}
                variant="contained"
                color="primary"
                sx={{ width: '100%', marginTop: '20px' }}
              >
                {capturedImages.back ? 'Retake' : 'Back'}
              </Button>

              <LoadingButton
                loading={loading}
                disabled={!checkedDisclaimer}
                onClick={handleContinueClick} variant="contained" color="primary" sx={{ width: '100%', marginTop: '20px' }}>
                {capturedImages.back ? 'Proceed' : 'Capture Back'}
              </LoadingButton>
            </>
          }

          {screen === PASSPORT_SCREENS.ShowConfirmation &&
            <>
              <Button
                disabled={loading}
                onClick={handleBackClick}
                variant="contained"
                color="primary"
                sx={{ width: '100%', marginTop: '20px' }}
              >
                Cancel
              </Button>

              <LoadingButton
                loading={loading}
                disabled={!checkedDisclaimer}
                onClick={handleContinueClick}
                variant="contained"
                color="primary"
                sx={{ width: '100%', marginTop: '20px' }}>
                Continue
              </LoadingButton>
            </>
          }
        </Box>
      </Box>
    </Container >
  )
}